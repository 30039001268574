import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import { Field, Form, Formik } from 'formik';
import { useSnackbar } from 'material-ui-snackbar-provider';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { companiesProvider } from '../../api';
import Button from '../common/Button';
import { SwitchWithLabel } from '../common/fields/Switch';

const featureFlags = [
    'usar_fichajes_vacaciones',
    'usar_referencias_tareas',
    'usar_marcajes_jornadas',
    'usar_preparacion_materiales',
    'usar_preparacion_materiales_lista',
    'usar_precios',
    'mostrar_precios_pdf',
    'usar_subtareas',
    'usar_firma_camara',
    'usar_incidencias',
    'usar_presupuestos',
    'usar_delegaciones',
    'usar_backups',
    'usar_checklists',
    'usar_documentos',
    'usar_notas_dia',
    'usar_revision_materiales',
    'usar_firma_albaranes_pendientes',
    'usar_ajuste_planificaciones_al_cerrar',
    'usar_servicios_libres',
    'usar_responsables',
    'usar_agrupar_tareas_proyectos_app',
    'usar_solicitudes_dias_naturales',
    'usar_solicitudes_material',
    'usar_nueva_ui_operarios',
    'usar_dnd_calendario_planificacion',
    'usar_albaranes_compra',
    'usar_hora_visita',
    'usar_hora_inicio_jornada',
    'usar_actualizar_jornada_from_fichaje',
    'usar_nueva_revision_fichajes',
    'usar_notificaciones_fichajes',
    'usar_telematel',
    'usar_delsol',
    'add_tiempo_operarios',
    'usar_envio_albaranes_adjuntos',
];

const FeatureFlagsSchema = Yup.object().shape(
    featureFlags.reduce((schema, flag) => {
        schema[flag] = Yup.bool().required('Requerido');
        return schema;
    }, {}),
);

export default function FeatureFlagsForm({ companyId }) {
    const [data, setData] = React.useState(null);
    const snackbar = useSnackbar();

    useEffect(() => {
        companiesProvider.getAll(`${companyId}/preferencias`).then((preferencias) => {
            setData(
                Object.keys(preferencias).reduce((acc, key) => {
                    if (featureFlags.includes(key)) {
                        acc[key] = preferencias[key];
                    }
                    return acc;
                }, {}),
            );
        });
    }, [companyId]);

    if (!data) return null;

    return (
        <Formik
            initialValues={data}
            enableReinitialize
            validationSchema={FeatureFlagsSchema}
            onSubmit={(values, actions) => {
                companiesProvider
                    .actionOnId(companyId, 'preferencias', values)
                    .then(() => {
                        snackbar.showMessage('Preferencias guardadas correctamente');
                    })
                    .catch((err) => {
                        snackbar.showMessage(err.message);
                    })
                    .finally(() => {
                        actions.setSubmitting(false);
                    });
            }}
        >
            {({ dirty, isSubmitting }) => (
                <Form>
                    <Grid container spacing={2}>
                        {featureFlags.map((flag) => (
                            <Grid item xs={12} key={flag}>
                                <Field
                                    name={flag}
                                    component={SwitchWithLabel}
                                    label={flag.replace(/_/g, ' ').toLowerCase()}
                                />
                            </Grid>
                        ))}
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Collapse in={dirty}>
                                <Button type='submit' color='info' startIcon={<SaveIcon />} disabled={isSubmitting}>
                                    Guardar
                                </Button>
                            </Collapse>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}

FeatureFlagsForm.propTypes = {
    companyId: PropTypes.any,
};
